<template>
  <VRow class="mx-1">
    <VCol cols="12">
      <h3
        v-if="form.type === 'new'"
        class="pb-6"
      >
        To bulk import a custom list of members, 
        <span
          class="text-primary"
          style="cursor: pointer;"
          @click="dialogs.import = !dialogs.import"
        >
          import a CSV
        </span>
        .
      </h3>
      <VSelect 
        v-model="form.type"
        dense
        outlined
        label="Choose type"
        :items="optionTypes"
      >
        <template #append-outer>
          <VBtn
            text
            height="40px"
            class="ml-4 mt-n2"
            @click="dialogs.sentInvitations = !dialogs.sentInvitations"
          >
            Sent Invitations
          </VBtn>
        </template>
      </VSelect>
      <template v-if="form.type == 'new'">
        <VSelect 
          v-model="form.role"
          dense
          outlined
          label="Select role"
          :items="roles"
          :error="$v.form.role.$error"
          @input="$v.$touch()"
          @blur="$v.$touch()"
        />
        <VCombobox
          v-model="form.users"
          clearable
          dense
          deletable-chips
          solo
          multiple
          outlined
          hide-details
          hide-no-data
          small-chips
          append-icon=""
          label="Enter email and press ENTER"
          placeholder="Enter email and press ENTER"
          :error="$v.form.users.$error"
          @input="$v.$touch()"
          @blur="$v.$touch()"
        />
      </template>
      <DataViewUI
        v-else
        show-select
        :data="data"
        :headers="headers"
        :is-loading="activity.isLoading"
        :filters="allFilters"
        @paginate="onGetMore"
        @search="onSearch"
        @selected="onSelectedItems"
      >
        <template
          slot="items-cells"
          slot-scope="props"
        >
          <td>
            <div style="width: max-content">
              <b
                :class="{
                  'success--text': props.item.id === $user.id
                }"
              > 
                {{ props.item.name }}
              </b>
            </div>
          </td>
          <td>
            <VChip
              rounded
              text-color="#000"
            >
              {{ props.item.role_name }}
            </VChip>
          </td>
          <td>{{ props.item.email || 0 }}</td>
          <td>{{ props.item.city ? props.item.city.name : '(not set)' }}</td>
          <td class="actions">
            <VBtn
              fab
              width="36px"
              min-width="36px"
              height="36px"
              class="wethrive"
              style="border-radius: 10px;"
              :disabled="selectedItems.length > 0 || props.item.isLoading"
              :loading="props.item.isLoading"
              @click="onAddPeople(props.index, props.item)"
            >
              <VIcon color="white">
                mdi-plus
              </VIcon>
            </VBtn>
          </td>
        </template>
      </DataViewUI>
      <Confirm
        ref="confirmInvite"
        confirm-color="info"
        cancel-color="disabled"
      />
      <Dialog
        max-width="750px"
        :is-dialog-open="dialogs.import"
        :title="`Invite new ${featureName('user').capitalize()} to ${model.title}`"
        @toggle:dialog="dialogs.import = !dialogs.import"
      >
        <Import :program="model" />
      </Dialog>
      <Dialog
        :is-dialog-open="dialogs.sentInvitations"
        :title="`View Sent Invitations for ${model.title}`"
        @toggle:dialog="dialogs.sentInvitations = !dialogs.sentInvitations"
      >
        <Invitations
          show-invitations-only
          :program-id="model.id"
        />
      </Dialog>
      <Dialog
        max-width="755px"
        :is-dialog-open="dialogs.invitations.status"
        :title="`View Sent Invitations for ${model.title}`"
        @toggle:dialog="dialogs.invitations.status = !dialogs.invitations.status"
      >
        <VCard>
          <VCardText v-if="nonExistingUsers.length">
            <VAlert
              color="rgba(73, 148, 236, 0.2)"
              class="subtitle-2 font-weight-medium"
              style="border-radius: 14px"
            >
              <template #prepend>
                <VIcon
                  color="#4994EC"
                  class="pr-3"
                >
                  mdi-information
                </VIcon>
              </template>
              These users don't have a WeThrive account and will be added to the {{ featureName("program", "singularize") }} once they accept the invite!
            </VAlert>
            <VChip
              v-for="(user, index) in nonExistingUsers"
              class="ma-2"
              :key="index"
            >
              {{ user.email }}
            </VChip>
          </VCardText>
          <VDivider
            v-if="hasExistingUsers && nonExistingUsers.length"
            class="my-2"
          />
          <VCardText v-if="hasExistingUsers">
            <VAlert
              color="rgba(73, 148, 236, 0.2)"
              class="subtitle-2 font-weight-medium"
              style="border-radius: 14px"
            >
              <template #prepend>
                <VIcon
                  color="#4994EC"
                  class="pr-3"
                >
                  mdi-information
                </VIcon>
              </template>
              These users already have WeThrive accounts and have automatically been added to the {{ featureName("program", "singularize") }}!
            </VAlert>
            <VChip
              v-for="(email, index) in existingUsers"
              class="ma-2"
              :key="index"
            >
              {{ email }}
            </VChip>
          </VCardText>
        </VCard>
      </Dialog>
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import SearchMixin from "@/mixins/Search";
import Import from "./Import";
import Invitations from "@/components/Users/Invitations";

export default {
  props: {
    model: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormMixin, SearchMixin],
  components: {
    Import,
    Invitations,
  },
  data() {
    return {
      search: null,
      isLoading: false,
      modelType: "users",
      optionTypes: [
        { text: "Invite Via Email", value: "new" },
        { text: "Search for Existing", value: "existing" },
      ],
      defaultForm: {
        type: "new",
        role: null,
        users: [],
        program_id: this.model.id,
      },
      dialogs: {
        import: false,
        sentInvitations: false,
        invitations: {
          data: [],
          status: false,
        },
      },
    }
  },
  watch: {
    "form.type": function (type) {
      this.form.users = [];
      this.$emit("changeButtonText", type === "new" ? "Invite" : "Add");
    },
  },
  validations: {
    form: {
      users: {
        required,
        $each: {
          email,
        }
      },
      role: {
        required: requiredIf(function() {
          return this.form.type === "new";
        }),
      },
    },
  },
  computed: {
    roles() {
      return this.$team.roles.filter((role) => Object.keys(this.model.visible_to).includes(role.value));
    },
    headers() {
      return [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Role",
          align: "left",
          sortable: true,
          value: "team_user.role",
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email",
        },
        {
          text: "City",
          align: "left",
          sortable: true,
          value: "city.name",
        },
      ];
    },
    nonExistingUsers() {
      return this.dialogs.invitations.data.filter((invitation) => !invitation.user_id && invitation.id);
    },
    hasExistingUsers() {
      // Existing users are adding directly with the message "User added into program." in the api response
      const result = this.dialogs.invitations.data.filter((invitation) => invitation.hasOwnProperty("message"));
      return result && result.length > 0;
    },
    existingUsers() {
      if(this.nonExistingUsers.length > 0) {
        return this.form.users.filter((email) => this.nonExistingUsers.findIndex((u) => u.email != email) != -1);
      }
      return this.form.users;
    },
  },
  methods: {
    ...mapActions({
      doGetUsers: "users/getAll",
      doSendInviteToProgrm: "team/inviteToProgram",
    }),
    onGetAll(params) {
      params.filter = {
        "team_user.role": Object.keys(this.model.visible_to),
      };
      return this.doGetUsers(params);
    },
    onAddPeople(index, user) {
      this.$refs
        .confirmInvite
        .open("Invite", `Are you sure you want to add ${user.name} to ${this.model.title.capitalize()}?`, {
          color: "info",
        })
        .then((status) => {
          if(status) {
            let params = {
              ...this.form,
              users: [{ email: user.email }],
            };
            this.form.users = [user.email];
            this.$set(this.data.data[index], "isLoading", true);
            this.doSendInviteToProgrm(params).then((result) => {
              this.$delete(this.data.data[index], "isLoading");
              this.$bus.$emit(
                "notificationMessage",
                "User added successfully"
              );
              this.showSentInvitations(result);
            });
          }
        });
    },
    onSave() {
      const params = {
        ...this.form,
      };
      if(this.selectedItems.length) {
        params.users = this.selectedItems.map((item) => {
          this.form.users.push(item.email);
          return { email: item.email }
        })
      } else {
        params.users = this.form.users.map((email) => {
          return { email }
        });
      }
      return this.saveFormModel(this.doSendInviteToProgrm, null, params, null, false).then((result) => {
        if(result) {
          this.$bus.$emit(
            "notificationMessage",
            "Invitation sent successfully"
          );
          this.showSentInvitations(result);
        }
      });
    },
    showSentInvitations(invitations) {
      this.$set(this.dialogs.invitations, "data", invitations);
      this.$set(this.dialogs.invitations, "status", true);
    },
  },
}
</script>
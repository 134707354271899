<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="card"
    :loading="(isFirstLoad && !isDashboard) || isLoading"
  >
    <VRow
      row
      wrap
      class="programs-faqs"
    >
      <VCol
        v-if="faqs.data.length === 0"
        cols="12"
      >
        <NoRecordCard
          :searched-text="filter.s"
          :action-text="`Create an ${ featureName('FAQs', 'singularize') }`"
          :title="`You don’t have any ${ featureName('FAQs') } yet`"
          :description="`You can add and edit your ${ featureName('Program', 'singularize') }’s Frequently Asked Questions. You can add ${ featureName('FAQs') } for ${ featureName('Students') } and/or ${ featureName('Mentors') }, and control the visibility to each.`"
          @clicked="$emit('addSection', sections[activeTab])"
        />
      </VCol>
      <template v-else>
        <VCol cols="12">
          <FaqsTable 
            v-if="!isTilesView" 
            :existing-data="faqs.data" 
            @duplicated="result => localFaqs.data.unshift(result)"
            @deleted="id => localFaqs.data = localFaqs.data.filter(faq => faq.id != id)"
            @updated="result => localFaqs.data = localFaqs.data.map(faq => faq.id === result.id ? result : faq)"
          />
          <VExpansionPanels 
            v-else 
            accordion
          >
            <VExpansionPanel
              v-for="(item, key) in faqs.data"
              style="box-shadow: 0 0.5px 0 0 #ececf1;"
              :key="item.id"
              :disabled="checkShowCount(key)"
              @click="onClick(key)"
            >
              <VExpansionPanelHeader 
                class="section-card-title"
                style="color: #000000;"
              >
                <VOverlay
                  absolute
                  light
                  :value="checkShowCount(key)"
                >
                  <span style="color: #ffffff; z-index: 6; font-size: 24px; font-weight: 800;"> 
                    +{{ (model.faqs_count - faqs.data.length) + 1 }}
                  </span>
                </VOverlay>
                <span class="text-wrap mr-10">
                  {{ item.title }}
                </span>
                <CardMenu 
                  v-if="$isUserAdmin && $viewProgramAsAdmin"
                  only-visibility-menu
                  class="mr-10 mt-1"
                  :item="item"
                  :item-type="modelType"
                  :parent-model="model"
                  @duplicated="(result) => localFaqs.data.unshift(result)"
                />
              </VExpansionPanelHeader>
              <VExpansionPanelContent class="pt-0">
                <FilesList
                  :readonly="true"
                  :files="item.files"
                />
                <p
                  v-linkified
                  v-html="item.content"
                  class="formatted content"
                  :style="{
                    'line-height': 1.4, 
                    'color': '#808080',
                    'font-size': '14px'
                  }"
                />
              </VExpansionPanelContent>
            </VExpansionPanel>
            <infinite-loading
              v-if="enableInfiniteLoader"
              :identifier="new Date()"
              @infinite="($state) => onInfiniteLoad($state, pagination)"
            >
              <template #no-more>
                <span />
              </template>
            </infinite-loading>
          </VExpansionPanels>
        </VCol>
      </template>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";
import FaqsTable from "@/components/FAQ/Table";
import CardMenu from "@/components/Elements/Navigation/CardMenu";
import NoRecordCard from "./NoRecordCard";
export default {
  name: "Faqs",
  mixins: [ViewProgramSection],
  components: {
    FaqsTable,
    CardMenu,
    NoRecordCard,
  },
  data() {
    return {
      tabName: "Faq",
      modelType: "faq",
      isLoading: false,
      localFaqs: {
        data: [],
      },
    }
  },
  watch: {
    activeTab: {
      handler: function(value) {
        // Only admin can see Applications tab, so next tab's index will alter
        if ((this.$isUserAdmin && value == 7) || ((!this.$isUserAdmin || !this.$viewProgramAsAdmin) && value == 6)) {
          this.filter.s = "";
          this.initSearch();
        } else {
          this.localFaqs.data = this.model.faqs;
        }
        this.enableInfiniteLoader = value > 0;
      },
      immediate: true,
    },
    model: {
      handler: function(value) {
        this.localFaqs.data = value.faqs;
      },
      deep: true,
    },
    infiniteLoad: {
      handler: function(value) {
        if(!this.isDashboard) {
          this.localFaqs = value;
        }
      },
      deep: true,
    },
  },
  computed: {
    faqs() {
      const availableFaqs = this.$viewProgramAsAdmin 
        ? this.localFaqs 
        : {
            ...this.localFaqs,
            data: this.localFaqs.data.filter((faq) => this.hasItemValidVisibility(faq, this.model.id)),
          };
      this.$store.commit('program/updateSectionsVisibility', { 
        faqs: availableFaqs.data.length > 0|| (this.$isUserMentor && this.checkMentorPermission(this.tabName, this.model)),
      });
      return availableFaqs;
    },
  },
  methods: {
    ...mapActions({
      doGetFaqs: "faq/getAll",
    }),
    onGetAll(params) {
      params.filter = {
        program: [this.model.id],
        strict: !this.filter.s,
      };
      params.s = this.filter.s;
      return this.doGetFaqs(params).then((result) => {
        if(this.isDashboard) {
          this.localFaqs = result;
        }
        return result;
      });
    },
    checkShowCount(key) {
      if(this.isDashboard) {
        return this.model.faqs_count > this.limitForDashboard && key === this.faqs.data.length - 1;
      }
      return false;
    },
    onClick(key) {
      if(this.checkShowCount(key)) {
        this.$emit('toggleTab', this.tabName.toLowerCase().pluralize());
      }
    },
  },
}
</script>
<style lang="scss">
.programs-faqs {
  .v-expansion-panel-content__wrap {
    padding: 0 12px 12px !important;
  }
}
</style>